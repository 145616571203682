import React, { useRef, useState } from 'react'
import Topbar from '../UI/Topbar'
import titleImage from '../images/financial-independent-woman-buying-new-car.jpg'
import { Box, Button } from '@mui/material'
import CompanyInfo from './CompanyInfo'
import cityImage from '../images/city.jpg'
import Services from './Services'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from '@react-google-maps/api'

const center = { lat: 48.8584, lng: 2.2945 }

function Home() {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    })
    // eslint-disable-next-line
    const [map, setMap] = useState(null)
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [distance, setDistance] = useState()
    const [duration, setDuration] = useState()
    const [timeContainerVisibility, setTimeContainerVisibility] = useState(false)
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [sourceError, setSourceError] = useState(false)
    const [destinationError, setDestinationError] = useState(false)
    const [scheduledDateError, setScheduledDateError] = useState(false)
    const [scheduledTimeError, setScheduledTimeError] = useState(false)
    const today = new Date().toISOString().split('T')[0];
    const Navigate = useNavigate()

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()

    if (!isLoaded) {
        return <div>Loading...</div>
    }

    const titleImageStyle = {
        height: '600px',
        width: '100%',
        objectFit: 'cover',
    }
    const titleImageContainer = {
        position: 'relative'
    }
    const pickupContainer = {
        position: 'absolute',
        top: '50%',
        right: '20%',
        transform: 'translate(0,-50%)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        background: 'white',
        padding: '30px'
    }
    const cityImageStyle = {
        width: '100%',
        height: '300px',
        objectFit: 'cover',
        filter: 'greyscale(5)'
    }


    async function calculateRoute() {


        if (originRef.current.value === '' || destiantionRef.current.value === '') {
            setSourceError(true)
            setDestinationError(true)
        } else {

            setTimeContainerVisibility(true)
            // eslint-disable-next-line no-undef
            const directionsService = new google.maps.DirectionsService()
            const results = await directionsService.route({
                origin: originRef.current.value,
                destination: destiantionRef.current.value,
                // eslint-disable-next-line no-undef
                travelMode: google.maps.TravelMode.DRIVING,
            })
            setDirectionsResponse(results)
            setDistance(results.routes[0].legs[0].distance.text)
            setDuration(results.routes[0].legs[0].duration.text)
        }

    }



    const findCarAction = () => {
        if (date === '') {
            setScheduledDateError(true)
        } else if (time === '') {
            setScheduledTimeError(true)
        } else {

            const xdata = {
                pickupLocation: originRef.current.value,
                destination: destiantionRef.current.value,
                date: date,
                time: time,
                distance: distance,
                duration: duration,
            }
            Navigate('/Select_Vehicle', { state: xdata })
        }
    }


    const schDateAction = (e) => {
        setScheduledDateError(false)
        setDate(e)
    }
    const schTimeAction = (e) => {
        setScheduledTimeError(false)
        setTime(e)
    }

    return (
        <div >
            <Topbar />
            <div style={titleImageContainer}>

                {timeContainerVisibility ? (
                    <Box style={{
                        position: 'absolute',
                        top: '50%',
                        left: '33%',
                        transform: 'translate(0, -50%)'
                    }}>
                        {/* Google Map Box */}
                        <GoogleMap
                            center={center}
                            zoom={1}
                            mapContainerStyle={{ width: '400px', height: '260px' }}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                            }}
                            onLoad={map => setMap(map)}
                        >
                            <Marker position={center} />
                            {directionsResponse && (
                                <DirectionsRenderer directions={directionsResponse} />
                            )}
                        </GoogleMap>
                    </Box>
                ) : ''}


                <img src={titleImage} alt='' style={titleImageStyle} />
                <div style={pickupContainer}>
                    <div>
                        <label className='text-secondary mb-1'>Pickup Location</label>

                        <Autocomplete>
                            <input type='text' className='form-control' ref={originRef} onChange={() => setSourceError(false)} />
                        </Autocomplete>
                        {sourceError ? (
                            <span className='text-danger'>Please select pickup location</span>
                        ) : ""}
                    </div>
                    <div>
                        <label className='text-secondary mb-1'>Destination</label>
                        <Autocomplete>
                            <input type='text' className='form-control' ref={destiantionRef} onChange={() => setDestinationError(false)} />
                        </Autocomplete>
                        {destinationError ? (
                            <span className='text-danger'>Please select destination location</span>
                        ) : ""}
                    </div>
                    <div>
                        {!timeContainerVisibility ? (
                            <Button
                                fullWidth
                                variant='contained'
                                style={{ background: '#000' }}
                                onClick={() => calculateRoute()}>
                                Get Route
                            </Button>
                        ) : ''}
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>{distance}</strong>
                        <strong>{duration}</strong>
                    </div>
                    {timeContainerVisibility ? (
                        <>
                            <div>
                                <label className='text-secondary mb-1'>Schedule Date</label>
                                <input className='form-control' type="date"  min={today} onChange={(e) => schDateAction(e.target.value)} />
                                {scheduledDateError ? (
                                    <span className='text-danger'>Please select a date</span>
                                ) : ""}
                            </div>
                            <div>
                                <label className='text-secondary mb-1'>Schedule Time</label>
                                <input className='form-control' type="time" onChange={(e) => schTimeAction(e.target.value)} />
                                {scheduledTimeError ? (
                                    <span className='text-danger'>Please select a time</span>
                                ) : ""}
                            </div>
                            <Button variant='contained' style={{ color: 'white', background: 'black', textTransform: 'inherit' }} onClick={() => findCarAction()}>Find a Car</Button>
                        </>
                    ) : ''}
                </div>
            </div>
            <div className='container p-4 mb-4 mt-4'>
                <CompanyInfo />
            </div>
            <div>
                <img src={cityImage} alt='' style={cityImageStyle} />
            </div>
            <div className='container p-4 mb-4 mt-4'>
                <Services />
            </div>
            <Footer />
        </div>
    )
}

export default Home