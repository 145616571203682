import React, { useState } from 'react'
import Footer from './Footer'
import Topbar from '../UI/Topbar'
import { MdPayment } from 'react-icons/md'
import { BsCashCoin, BsBank } from 'react-icons/bs'
import { BiWalletAlt } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { instance } from '../Const/ApiHeader'
import { CHECKOUT_ACTION, NEW_TRIP } from '../Const/ApiConst'
import { LoadingButton } from '@mui/lab'
import Cookies from 'js-cookie'


function PaymentPage() {
    const Navigate = useNavigate()
    const Location = useLocation()
    const CustData = JSON.parse(Cookies.get('udtl'))
    const [confirmPaymentButtonLoading, setConfirmPaymentButtonLoading] = useState(false)
    console.log(CustData.uid);

    const paymentIconStyle = {
        color: '#808080',
        fontSize: '25px'
    }

    console.log(Location.state);

    const proceedToPayment = () => {
        setConfirmPaymentButtonLoading(true)
        const data = {
            source: Location.state?.source,
            destination: Location.state.destination,
            vehicleId: Location.state.vehicleData[0]._id,
            customerId: CustData.uid,
            scheduledDate: Location.state.scheduledDate,
            scheduledTime: Location.state.travelTime,
            shortDescription: Location.state.shortDescription,
            totalAmount: Location.state.grandTotal,
            paymentStatus: "Completed",
            paymentReference: "123123",
            paymentMode: "Cash on Trip",
            // noOfPassengers:
        }
        instance.post(
            NEW_TRIP,
            data
        ).then((response) => {
            Navigate('/Order_Confirm', { state: response.data.data.tripNo })
            setConfirmPaymentButtonLoading(false)
        })
    }
    return (
        <div>
            <Topbar />

            <div className='container trip-container mt-4 mb-4'>
                <div>
                    <ul className='nostyleUl'>
                        <li className='p-2'>
                            <div className='d-flex gap-2 align-items-center'>
                                <input type='radio' checked />
                                <div style={paymentIconStyle}><BsCashCoin /></div>
                                <div>
                                    <div>Cash On Drive</div>
                                    <div>Pay by Cash at the time of driver arrive</div>
                                </div>
                            </div>
                        </li>
                        <li className='p-2' style={{ background: '#e0e0e0' }}>
                            <div className='d-flex gap-2 align-items-center'>
                                <input type='radio' disabled />
                                <div style={paymentIconStyle}><MdPayment /></div>
                                <div className='text-secondary'>
                                    <div>Debit/Credit Card</div>
                                    <div>This payment mode currently unavilable</div>
                                </div>
                            </div>
                        </li>
                        <li className='p-2' style={{ background: '#e0e0e0' }}>
                            <div className='d-flex gap-2 align-items-center'>
                                <input type='radio' disabled />
                                <div style={paymentIconStyle}><BsBank /></div>
                                <div className='text-secondary'>
                                    <div>Bank Transfer</div>
                                    <div>This payment mode currently unavilable</div>
                                </div>
                            </div>
                        </li>
                        <li className='p-2' style={{ background: '#e0e0e0' }}>
                            <div className='d-flex gap-2 align-items-center'>
                                <input type='radio' disabled />
                                <div style={paymentIconStyle}><BiWalletAlt /></div>
                                <div className='text-secondary'>
                                    <div>UPI</div>
                                    <div>This payment mode currently unavilable</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='text-center'>
                    <LoadingButton
                        loading={confirmPaymentButtonLoading}
                        variant='contained'
                        onClick={() => proceedToPayment()}
                    >Proceed payment</LoadingButton>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PaymentPage