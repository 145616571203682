import React from 'react'
import Topbar from '../UI/Topbar'
import Footer from './Footer'
import successIcon from '../images/payment_success_image.png'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'

function OrderConfirmationPage() {

    const Location = useLocation()

    const succesImageStyle = {
        width: "150px"
    }
    return (
        <div>
            <Topbar />
            <div className='container trip-container p-4'>
                <div className='text-center'><img src={successIcon} alt='' style={succesImageStyle} /></div>
                <h1 className='text-center'><strong>Trip Confirmed!</strong></h1>
                <h2 className='text-center'><strong>Trip ID # {Location.state}</strong></h2>
                <p className='text-center'><strong>Thank you for using our services</strong></p>
                <p className='text-center'>Our car rental service offers an easy and convenient way to rent a vehicle for your travel needs. With a wide range of vehicles to choose from, including sedans, SUVs, and trucks, you can find the perfect vehicle to suit your needs and budget. Our rental process is fast and hassle-free, allowing you to quickly reserve and pick up your rental vehicle. We also offer flexible rental options, including daily, weekly, and monthly rentals, giving you the flexibility to rent a vehicle for as long as you need. Our rental vehicles are well-maintained and regularly serviced to ensure your safety and comfort on the road. With competitive rental rates and excellent customer service, renting a vehicle from our car rental service is a smart choice for your travel needs.</p>
                <p className='text-center'><strong className="text-primary">for any queries, Please reach us on +1 (1252)3555 111 or mail us to info@carrentservice.com</strong></p>
                <div className='text-center'><Link to='/'><Button variant='contained' style={{ background: '#000' }}>Go Home</Button></Link></div>
            </div>
            <Footer />
        </div>
    )
}

export default OrderConfirmationPage