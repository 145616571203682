import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Topbar from '../UI/Topbar'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_VEHICLE_LIST, IMAGE_BASE_URL, SIGNUP_WITH_EMAIL, SIGN_IN_WITH_GOOGLE, UPDATE_USER_PHONE } from '../Const/ApiConst'
import { instance } from '../Const/ApiHeader'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import moment from 'moment/moment'
import { Alert, Box, Button, Modal, Skeleton, TextField } from '@mui/material'
import { Form } from 'react-bootstrap'
import { FcGoogle } from 'react-icons/fc'
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { auth, provider } from '../firebase'
import LoadingButton from '@mui/lab/LoadingButton';
import Cookies from 'js-cookie'


// Signin Popup style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};




function SelectVehicle() {
    const Navigate = useNavigate()
    const Location = useLocation()
    const [vehicleData, setVehicleData] = useState([])
    const [source, setSource] = useState('')
    const [destination, setDestination] = useState('')
    const [travelDate, setTravelDate] = useState('')
    const [travelTime, setTravelTime] = useState('')
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openPhoneUpdateModal, setOpenPhoneUpdateModal] = useState(false)
    const handleOpenPhoneUpdateModal = () => setOpenPhoneUpdateModal(true);
    const handleClosePhoneUpdateModal = () => setOpenPhoneUpdateModal(false);
    const [signUpContainer, setSignUpContainer] = useState(false)
    const [authFormTitle, setAuthFormTitle] = useState('Sign in')
    const [updatePhone, setUpdatePhone] = useState('')
    // Auth States
    const [authEmail, setAuthEmail] = useState('')
    const [authPassword, setAuthPassword] = useState('')
    const [authError, setAuthError] = useState('')
    const [fullName, setFullName] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [TripData, setTripData] = useState([])

    const [loadSkeletonData, setLoadSkeletonData] = useState(true)



    const LoadVehicleDetails = async () => {
        try {
            await instance.post(
                GET_VEHICLE_LIST
            ).then(result => {
                setVehicleData(result.data.data)
                setLoadSkeletonData(false)
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        LoadVehicleDetails()
        setSource(Location.state?.pickupLocation)
        setDestination(Location.state?.destination)
        setTravelDate(Location.state?.date)
        setTravelTime(Location.state?.time)
        // eslint-disable-next-line
    }, [])


    const bookTripAction = (e) => {
        const tpData = {
            pickupLocation: Location.state?.pickupLocation,
            destination: Location.state?.destination,
            date: Location.state?.date,
            time: Location.state?.time,
            distance: Location.state?.distance,
            duration: Location.state?.duration,
            vhid: e._id
        }
        setTripData(tpData)
        
        Navigate('/Review_Booking', { state: tpData })
    }


    const loginAction = async () => {
        try {
            await signInWithEmailAndPassword(auth, authEmail, authPassword)
                .then(response => {
                    Navigate('/Review_Booking', { state: TripData })
                }).catch(err => {
                    if (err.message === "Firebase: Error (auth/user-not-found)") {
                        setAuthError("User Not found")
                    } else {
                        setAuthError("Username or password are not matching")
                    }
                })
        } catch (error) {
            console.log(error.error);
        }
    }

    const signupAction = async () => {
        setSignUpLoading(true)
        if (fullName === '') {
            setAuthError('Full name required')
            setSignUpLoading(false)
        } else if (contactNumber === '') {
            setAuthError('Contact Number required')
            setSignUpLoading(false)
        } else {
            try {
                await createUserWithEmailAndPassword(auth, authEmail, authPassword)
                    .then(result => {
                        setSignUpLoading(false)
                        Cookies.set('photoURL', "No Image")
                        sessionStorage.setItem("attknres", result.user.accessToken);

                        const data = {
                            uid: result.user.uid,
                            fullName: fullName,
                            email: result.user.email,
                            phoneNumber: contactNumber,
                        }
                        instance.post(
                            SIGNUP_WITH_EMAIL,
                            data
                        ).then(response => {
                            handleClose()
                            setAuthError('')
                            setSignUpContainer(false)
                            Cookies.set('udtl', JSON.stringify(data))
                            Navigate('/Review_Booking', { state: TripData })
                        })
                    })
            } catch (error) {
                console.log(error.message);
                switch (error.message) {
                    case 'Firebase: Error (auth/missing-password).':
                        setAuthError('Password cannot be empty')
                        break;
                    case 'Firebase: Error (auth/invalid-email).':
                        setAuthError('Invalid Email')
                        break;
                    case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
                        setAuthError('Password should be at least 6 characters.')
                        break;
                    case 'Firebase: Error (auth/email-already-in-use).':
                        setAuthError('Email already in use.')
                        break;
                    default:
                        break;
                }
                setSignUpLoading(false)
            }
        }
    }

    const [resetPasswordEmail, setResetPasswordEmail] = useState('')
    const [restEmailError, setRestEmailError] = useState()
    const restPasswordAction = async () => {
        if (resetPasswordEmail === '') {
            setRestEmailError('Please enter email')
        } else {

            try {
                await sendPasswordResetEmail(auth, resetPasswordEmail)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            } catch (error) {
                console.log(error);
            }
        }
    }

    const signUpForm = () => {
        if (signUpContainer === false) {
            return <>
                <Form.Control
                    placeholder='Email'
                    onChange={(e) => setAuthEmail(e.target.value)} />
                <Form.Control
                    placeholder='Password'
                    type='password'
                    onChange={(e) => setAuthPassword(e.target.value)} />
                <Button
                    variant='contained'
                    style={{ background: 'black' }}
                    onClick={() => loginAction()}>Login</Button>
                <div className='text-end text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Reset Password') + setSignUpContainer('restPassword')}> Forgot password? </div>
                <div className='d-flex'>
                    < div > Don't have an account? Click &nbsp;</div>
                    < div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Sign Up') + setSignUpContainer(true)}> Here</div >
                    <div> &nbsp; to signup</div>
                </div >
            </>
        } else if (signUpContainer === 'restPassword') {
            return <>
                <TextField
                    error={restEmailError}
                    helperText={restEmailError}
                    size='small'
                    label='Reset Password'
                    onChange={(e) => setRestEmailError('') + setResetPasswordEmail(e.target.value)} />
                <Button variant='contained' onClick={() => restPasswordAction()}>Reset</Button>
                <div className='d-flex'>
                    < div > Already have an account? Click &nbsp;</div>
                    < div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Sign In') + setSignUpContainer(false)}> Here</div >
                    <div>&nbsp; to Login</div>
                </div >
            </>
        } else {
            return <>
                <Form.Control
                    placeholder='Full Name'
                    onChange={(e) => setFullName(e.target.value)} />
                <Form.Control
                    placeholder='Contact Number'
                    onChange={(e) => setContactNumber(e.target.value)} />
                <Form.Control
                    placeholder='Email'
                    onChange={(e) => setAuthEmail(e.target.value)} />
                <Form.Control
                    placeholder='Password'
                    type='password'
                    onChange={(e) => setAuthPassword(e.target.value)} />
                <LoadingButton
                    loading={signUpLoading}
                    variant="outlined"
                    onClick={() => signupAction()}
                >
                    Sign Up
                </LoadingButton>
                <div className='d-flex'>
                    < div > Already have an account? Click &nbsp;</div>
                    < div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Sign In') + setSignUpContainer(false)}> Here</div >
                    <div>&nbsp; to Login</div>
                </div >
            </>

        }

    }


    const signInGoogle = () => {
        // signInWithGoogle 
        signInWithPopup(auth, provider).then((result) => {
            Cookies.set('photoURL', result.user.photoURL)
            const data = {
                uid: result.user.uid,
                fullName: result.user.displayName,
                email: result.user.email,
                phoneNumber: result.user.phoneNumber,
                userImage: result.user.photoURL
            }

            instance.post(
                SIGN_IN_WITH_GOOGLE,
                data
            ).then(result => {
                console.log(result);
            })

            Cookies.set('udtl', JSON.stringify(data))
            handleClose()
            Navigate('/Review_Booking', { state: TripData })
        }).catch((error) => {
            console.log(error)
        })
    }


    // const updateUserContactNumberAction = async () => {
    //     console.log(JSON.parse(Cookies.get("udtl")));
    //     // const data = {
    //     //     uid: JSON.parse(Cookies.get("udtl").uid),
    //     //     phone: updatePhone
    //     // }
    //     // await instance(UPDATE_USER_PHONE, data)
    //     //     .then(response => {
    //     //         Cookies.set('udtl', response.data)
    //     //         setOpenPhoneUpdateModal(false)
    //     //     })
    // }
    return (
        <div>
            <Topbar />
            <div className='container trip-container'>
                <h5><strong>{source} <TrendingFlatIcon /> {destination}</strong></h5>
                <div className='d-flex gap-2 align-items-center w-100'>
                    <div className='bg-warning p-1'>{moment(travelDate).format("dddd, MMM DD")}</div>
                    <small>{moment.utc().startOf('day').add(moment.duration(travelTime)).format('hh:mm a')}</small>
                </div>
            </div>
            <div className='container mb-4'>
                {loadSkeletonData ? (
                    <div className='d-flex flex-column '>
                        <div className='d-flex align-items-center justify-content-between trip-container mt-4'>
                            <div className='d-flex flex-column gap-0' style={{ width: '10%' }}>
                                <Skeleton
                                    animation="wave"
                                    style={{
                                        marginBottom: 6,
                                        width: '150px',
                                        height: '150px'
                                    }}
                                />
                            </div>
                            <div className='w-50'>
                                <div className='d-flex gap-4'>
                                    <Skeleton width={'100%'} />
                                    <Skeleton width={'100%'} />
                                </div>
                                <div className='d-flex gap-4'>
                                    <Skeleton width={'100%'} />
                                    <Skeleton width={'100%'} />
                                </div>
                            </div>
                            <div style={{ width: '15%' }}>
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between trip-container mt-4'>
                            <div className='d-flex flex-column gap-0' style={{ width: '10%' }}>
                                <Skeleton
                                    animation="wave"
                                    style={{
                                        marginBottom: 6,
                                        width: '150px',
                                        height: '150px'
                                    }}
                                />
                            </div>
                            <div className='w-50'>
                                <div className='d-flex gap-4'>
                                    <Skeleton width={'100%'} />
                                    <Skeleton width={'100%'} />
                                </div>
                                <div className='d-flex gap-4'>
                                    <Skeleton width={'100%'} />
                                    <Skeleton width={'100%'} />
                                </div>
                            </div>
                            <div style={{ width: '15%' }}>
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                            </div>
                        </div>
                    </div>
                ) : (

                    vehicleData.map((res, i) => (
                        <div key={i} className='d-flex align-items-center justify-content-between trip-container mt-4'>
                            <div>
                                <img
                                    src={IMAGE_BASE_URL + res.images[0]}
                                    alt=''
                                    style={{ width: '150px' }} />
                                <div className='text-center'>{res.vehicleName} </div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <div className='me-4'> From: {source}</div>
                                    <div className='ms-4'>To: {destination}</div>
                                </div>
                                <div className='d-flex'>
                                    <div className='me-4'>
                                        Distance : {Location.state?.distance}
                                    </div>
                                    <div className='ms-4'>
                                        Time : {Location.state?.duration}
                                    </div>
                                </div>
                            </div>
                            <div>

                                {parseInt(Location.state?.distance) > parseInt(res.baseDistance) ? (
                                    <h5 className='text-center'><strong>₹ {(parseInt(Location.state?.distance) - parseInt(res.baseDistance)) * parseInt(res.pricePerUnitDistance) + parseInt(res.basePrice)}</strong></h5>
                                ) : (
                                    <h5 className='text-center'><strong>₹ {parseInt(res.basePrice)}</strong></h5>
                                )}

                                <Button
                                    variant='contained'
                                    onClick={() => bookTripAction(res)}
                                >Book Now</Button>

                            </div>
                        </div>
                    ))

                )}

            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 className='text-center mb-4'><strong>{authFormTitle}</strong></h3>
                    <div className='text-center d-flex flex-column gap-3'>
                        {authError ? (
                            <Alert severity="error" >{authError}</Alert>
                        ) : ""}
                        {signUpForm()}
                        <div className='d-flex'>
                            <hr className='w-100' />
                            <div>Or</div>
                            <hr className='w-100' />
                        </div>
                        <strong>Signin with</strong>
                        <div>
                            <Button
                                variant='contained'
                                startIcon={<FcGoogle style={{ background: '#fff' }}
                                />}
                                onClick={() => signInGoogle()}
                            >Google</Button>
                        </div>
                    </div>

                </Box>
            </Modal>


            {/* <Modal
                open={openPhoneUpdateModal}
                onClose={handleClosePhoneUpdateModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='mb-3 text-center'><h5><strong>Update Contact Number</strong></h5></div>
                    <TextField label="Contact number" size='small' onChange={(e) => setUpdatePhone(e.target.value)} />
                    <Button variant='contained' onClick={() => updateUserContactNumberAction()}>Save</Button>
                </Box>
            </Modal> */}
            <Footer />
        </div>
    )
}

export default SelectVehicle