import React, { useEffect, useState } from 'react'
import Topbar from '../UI/Topbar'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Col, Row } from 'react-bootstrap';
import { Button, TextField } from '@mui/material';
import { MdPayments } from 'react-icons/md'
import { instance } from '../Const/ApiHeader';
import { GET_SINGLE_VEHICLE, IMAGE_BASE_URL } from '../Const/ApiConst';
import Footer from './Footer';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api'

const center = { lat: 48.8584, lng: 2.2945 }

function ReviewBookings() {

  // eslint-disable-next-line
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  })


  const Navigate = useNavigate()
  const Location = useLocation()
  const [vehicleData, setVehicleData] = useState([])
  const [source, setSource] = useState(Location.state?.pickupLocation)
  const [destination, setDestination] = useState(Location.state?.destination)
  const [travelTime, setTravelTime] = useState('')
  const [paymentTypeDistance, setPaymentTypeDistance] = useState(true)
  const [shortDescription, setShortDescription] = useState('')
  const [directionsResponse, setDirectionsResponse] = useState(null)
  // eslint-disable-next-line
  const [map, setMap] = useState(null)
  // New Imported States

  const [firstImage, setFirstImage] = useState()
  const [baseFare, setBaseFare] = useState(0)
  const [baseFareKms, setBaseFareKms] = useState(0)
  const [additionalFareHours, setAdditionalFareHours] = useState(0)
  const [additionalFareKms, setAdditionalFareKms] = useState(0)
  const [baseDistance, setBaseDistance] = useState(0)

  var grandTotal = 0

  const loadMapData = async () => {

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: source,
      destination: destination,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
  }


  useEffect(() => {
    loadMapData()
    // eslint-disable-next-line
  }, [])

  const VehicleDataAction = () => {
    const data = {
      vhid: Location.state?.vhid
    }

    instance.post(
      GET_SINGLE_VEHICLE,
      data
    ).then(res => {
      setVehicleData(res.data.data)
      setFirstImage(res.data.data[0].images[0]);
      setBaseFare(res.data.data[0].basePrice);
      setBaseFareKms(res.data.data[0].baseDistance)
      setAdditionalFareKms(res.data.data[0].pricePerUnitDistance);
      setAdditionalFareHours(res.data.data[0].pricePerUnitHour);
      setBaseDistance(res.data.data[0].baseDistance)
      if (res.data.data[0].baseDistance > (Location.state?.toKms - Location.state?.fromKms)) {
        grandTotal = res.data.data[0].basePrice
      } else {
        grandTotal = (((Location.state?.toKms - Location.state?.fromKms) - res.data.data[0].baseDistance) * (res.data.data[0].pricePerUnitDistance)) + parseInt(baseFare)
      }



    })
  }


  useEffect(() => {
    VehicleDataAction()
    setSource(Location.state?.pickupLocation)
    setDestination(Location.state?.destination)
    setTravelTime(Location.state?.time)
    // eslint-disable-next-line
  }, [])



  const makePaymentAction = () => {
    const data = {
      vehicleData: vehicleData,
      source: source,
      destination: destination,
      scheduledDate: Location.state.date,
      travelTime: travelTime,
      paymentTypeDistance: paymentTypeDistance,
      firstImage: firstImage,
      travelKms: Location.state?.toKms - Location.state?.fromKms,
      baseFare: baseFare,
      baseFareKms: baseFareKms,
      additionalFareHours: additionalFareHours,
      additionalFareKms: additionalFareKms,
      grandTotal: grandTotal,
      baseDistance: baseDistance,
      shortDescription: shortDescription
    }
    Navigate('/Payment', { state: data })
  }


  const loadVehicle = () => {
    return vehicleData.map((res, i) => {
      return <div key={i} className='d-flex gap-4'>
        <Col>
          <div>
            <img
              src={IMAGE_BASE_URL + firstImage}
              alt=''
              style={{
                width: '50vw',
                height: '50vh',
                objectFit: 'cover'
              }} /></div>
          <div className='d-flex gap-2'>
            {res.images.map((res, ii) => (
              <div className='mt-2' key={ii}>
                <img
                  src={IMAGE_BASE_URL + res}
                  alt=''
                  style={{
                    width: '150px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setFirstImage(res)}
                />
              </div>
            ))}
          </div>
        </Col>
        <Col>
          <div className='m-4'>Vehicle name : {res.vehicleName}</div>
          <div className='m-4'>Vehicle No : {res.vehicleNo}</div>
          <div className='m-4'>Maximum Allowed Travellers : {res.maxPersons}</div>
          <div className='m-4'>Maximum Allowed Bags: -</div>
        </Col>
      </div>
    })
  }
  const changePaymentAction = (e) => {

    switch (e.target.value) {
      case "By Distance":
        setPaymentTypeDistance(true)
        break;
      case "By Time":
        setPaymentTypeDistance(false)
        break;

      default:
        break;
    }
  }
  return (
    <div>
      <Topbar />
      <div className='container trip-container'>
        <h5><strong>{source} <TrendingFlatIcon /> {destination}</strong></h5>
        <div className='d-flex gap-2 align-items-center w-100'>
          <div className='bg-warning p-1'>{moment(Location.state?.date).format("dddd, MMM DD")}</div>
          <small>{travelTime} Hrs</small>
        </div>
      </div>
      <div className='container p-0'>

        <div style={{
          width: '100%'
        }}>
          {/* Google Map Box */}
          <GoogleMap
            center={center}
            zoom={1}
            mapContainerStyle={{ width: '100%', height: '350px' }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={map => setMap(map)}
          >
            <Marker position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        </div>
      </div>
      <div className='container'>
        <div>
          {loadVehicle()}
        </div>
        <Row >
          <Col className='trip-container mt-1 ms-2 d-flex gap-4 flex-column mb-4'>
            <Col className='d-flex justify-content-between'>
              <div>Pickup Location</div>
              <div><input type='text' className='form-control' value={source} disabled /></div>
            </Col>
            <Col className='d-flex justify-content-between'>
              <div>Destination</div>
              <div><input type='text' className='form-control' value={destination} disabled /></div>
            </Col>
            {paymentTypeDistance ? (
              <Col className='d-flex justify-content-between'>
                <div>Distance</div>
                <div>{Location.state?.distance}</div>
              </Col>
            ) : (
              <Col className='d-flex justify-content-between'>
                <div>Time</div>
                <div>{Location.state?.duration}</div>
              </Col>
            )}
            <Col className='d-flex justify-content-between'>
              <div>Payment Type</div>
              <div>
                <select className='form-control' onChange={(e) => changePaymentAction(e)} disabled>
                  <option>By Distance</option>
                  <option>By Time</option>
                </select>
              </div>
            </Col>
            <TextField label='Description'
              size='small'
              multiline
              fullWidth
              onChange={(e) => setShortDescription(e.target.value)}
              rows={4} />
          </Col>
          <Col className='trip-container mt-1 ms-2 d-flex gap-4 flex-column mb-4'>
            <Col className='d-flex justify-content-between'>
              <div>Base Rate</div>
              <div><input type='text' className='form-control text-end' value={baseFare} disabled /></div>
            </Col>
            <Col className='d-flex justify-content-between'>
              <div>Base Km</div>
              <div><input type='text' className='form-control text-end' value={baseFareKms} disabled /></div>
            </Col>
            <Col className='d-flex justify-content-between'>
              {paymentTypeDistance ? (
                <>
                  <div>Rate per Km</div>
                  <div><input type='text' className='form-control text-end' value={additionalFareKms} disabled /></div>
                </>
              ) : (
                <>
                  <div>Rate per Hour</div>
                  <div><input type='text' className='form-control text-end' value={additionalFareHours} disabled /></div>
                </>
              )}
            </Col>
            <hr />
            <Col className='d-flex justify-content-between'>
              <div><strong>Grand Total</strong></div>
              <div>
                <strong>
                  {paymentTypeDistance ? (
                    parseFloat(Location.state?.distance) > parseFloat(baseDistance) ? (
                      <input type='text' className='form-control text-end' value={grandTotal = (parseFloat(Location.state?.distance.replace(' km', '') - parseFloat(baseDistance)) * parseFloat(additionalFareKms)) + parseFloat(baseFare)} disabled />
                    ) : (
                      <input type='text' className='form-control text-end' value={grandTotal = baseFare} disabled />
                    )
                  ) : (
                    <input type='text' className='form-control text-end' value={grandTotal + 2} disabled />
                  )}
                </strong>
              </div>
            </Col>
            <div className='mt-4 text-center'>
              <Button
                variant='contained'
                // style={{ background: '#000' }}
                color='primary'
                startIcon={<MdPayments />}
                onClick={() => makePaymentAction()}   >Proceed to payment</Button>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  )
}

export default ReviewBookings