import React from 'react'

function Footer() {
  return (
    <div style={{ background: '#000', color: '#fff' }}>
      <div className='d-flex justify-content-between container p-4'>
        <div>
          <ul className='nostyleUl'>
            <li><strong>Company</strong></li>
            <li>About us</li>
            <li>Our offerings</li>
            <li>Newsroom</li>
            <li>Investors</li>
            <li>Blog</li>
            <li>Careers</li>
            <li>AI</li>
            <li>Gift cards</li>
          </ul>
        </div>
        <div>
          <ul className='nostyleUl'>
            <li><strong>Products</strong></li>
            <li>Ride</li>
            <li>Drive</li>
            <li>Deliver</li>
            <li>Eat</li>
            <li>Uber for Business</li>
            <li>Uber Freight</li>
          </ul>
        </div>
        <div>
          <ul className='nostyleUl'>
            <li><strong>Global citizenship</strong> </li>
            <li> Safety</li>
            <li>Diversity and Inclusion</li>
            <li> Sustainability</li>
          </ul>
        </div >
      </div >
    </div >
  )
}

export default Footer