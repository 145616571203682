// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDRA4tVZYLNYFj7JvCnI69NxLERQyvmmPM",
    authDomain: "rentavehicle-106a4.firebaseapp.com",
    projectId: "rentavehicle-106a4",
    storageBucket: "rentavehicle-106a4.appspot.com",
    messagingSenderId: "143246604149",
    appId: "1:143246604149:web:41517e26f807f001c3053c",
    measurementId: "G-M9DRF5V58E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app)

export const provider = new GoogleAuthProvider()

export const signInWithGoogle = () => {

    signInWithPopup(auth, provider).then((result) => {
        sessionStorage.setItem('email', result.user.email)
        sessionStorage.setItem('displayName', result.user.displayName)
        sessionStorage.setItem('photoURL', result.user.photoURL)

    }).catch((error) => {
        console.log(error)
    })
}