const BACKEND_BASE_URL = process.env.REACT_APP_BASE_URL
// Image
export const IMAGE_BASE_URL = `${BACKEND_BASE_URL}/vehicle_images/`
// Routes
export const GET_VEHICLE_LIST = `${BACKEND_BASE_URL}/api/vehicles/get_vehicle_list`
export const GET_SINGLE_VEHICLE = `${BACKEND_BASE_URL}/api/vehicles/get_single_vehicle`

// Create User
export const SIGNUP_WITH_EMAIL = `${BACKEND_BASE_URL}/api/customers/signup_with_email`
export const SIGN_IN_WITH_GOOGLE = `${BACKEND_BASE_URL}/api/customers/signup_with_google`
export const UPDATE_USER_PHONE = `${BACKEND_BASE_URL}/api/customers/update_user_phone`

// Trips
export const NEW_TRIP = `${BACKEND_BASE_URL}/api/trip/new_trip`
export const GET_TRIP_DATA = `${BACKEND_BASE_URL}/api/trip/get_trip`
export const CANCEL_TRIP_DATA = `${BACKEND_BASE_URL}/api/trip/cancel_trip`

// Payments
export const CHECKOUT_ACTION = `${BACKEND_BASE_URL}/api/payments/create-checkout-session`