import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Modal,
    Tooltip
} from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import React, { useState } from 'react'
import companyLogo from '../images/company_logo.png'
import Logout from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import Login from './Login';
import HomeIcon from '@mui/icons-material/Home';
import Cookies from 'js-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 2,
};

function Topbar() {
    const Navigate = useNavigate()
    const topbarStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px',
        background: '#000',
        colorl: '#fff',
    }
    const topbarLogoStyle = {
        height: '60px'
    }

    // eslint-disable-next-line
    const [photo, setPhoto] = useState(false || Cookies.get('photoURL'))

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const logoutAction = async () => {
        await signOut(auth).then(res => {
            Cookies.remove('photoURL')
            Cookies.remove('udtl')
            sessionStorage.clear()
            Navigate("/")
            window.location.reload()
        })
    }


    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);


    return (
        <div style={topbarStyle}>
            <div className='text-white'><img src={companyLogo} alt='' style={topbarLogoStyle} /></div>

            <React.Fragment>
                {
                    photo ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar alt="Remy Sharp" src={sessionStorage.getItem('photoURL')} />
                                </IconButton>
                            </Tooltip>
                        </Box>

                    ) : <Button size='small' variant='contained' style={{ background: '#fff', color: '#000' }} onClick={() => handleOpenModal()}>Sign in</Button>
                }

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            width: "200px",
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >


                    <Link to='/'>
                        <MenuItem onClick={handleClose} style={{ fontSize: '14px' }}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <strong> Home</strong>
                        </MenuItem>
                    </Link>
                    <Link to='/Profile'>
                        <MenuItem onClick={handleClose} style={{ fontSize: '14px' }}>
                            <ListItemIcon>
                                <DriveEtaIcon />
                            </ListItemIcon>
                            <strong> Profile & Rides</strong>
                        </MenuItem>
                    </Link>
                    <Divider />
                    <MenuItem onClick={() => logoutAction()} style={{ fontSize: '14px' }}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <strong>Logout</strong>
                    </MenuItem>
                </Menu>
            </React.Fragment>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Login />
                </Box>
            </Modal>
        </div >
    )
}

export default Topbar