import { Alert, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup
} from 'firebase/auth'
import { auth, provider } from '../firebase'
import { instance } from '../Const/ApiHeader'
import { SIGNUP_WITH_EMAIL, SIGN_IN_WITH_GOOGLE } from '../Const/ApiConst'
import { Form } from 'react-bootstrap'
import LoadingButton from '@mui/lab/LoadingButton';
import { FcGoogle } from 'react-icons/fc'
import Cookies from 'js-cookie';


function Login() {

    const [authFormTitle, setAuthFormTitle] = useState('Sign in')
    const [authError, setAuthError] = useState('')
    const [fullName, setFullName] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [authEmail, setAuthEmail] = useState('')
    const [authPassword, setAuthPassword] = useState('')
    const [signUpContainer, setSignUpContainer] = useState(false)
    const [resetPasswordEmail, setResetPasswordEmail] = useState('')
    const [restPasswordLoading, setRestPasswordLoading] = useState(false)
    const [restEmailError, setRestEmailError] = useState()
    const [passwordResetSuccessmessage, setPasswordResetSuccessmessage] = useState(false)
    const [googleSigninButtonLoading, setGoogleSigninButtonLoading] = useState(false)




    const signupAction = async () => {
        setSignUpLoading(true)
        if (fullName === '') {
            setAuthError('Full name required')
            setSignUpLoading(false)
        } else if (contactNumber === '') {
            setAuthError('Contact Number required')
            setSignUpLoading(false)
        } else {
            try {
                await createUserWithEmailAndPassword(auth, authEmail, authPassword)
                    .then(result => {
                        setSignUpLoading(false)
                        sessionStorage.setItem('photoURL', "No Image")
                        sessionStorage.setItem("attknres", result.user.accessToken);

                        const data = {
                            uid: result.user.uid,
                            fullName: fullName,
                            email: result.user.email,
                            phoneNumber: contactNumber,
                        }
                        Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
                        instance.post(
                            SIGNUP_WITH_EMAIL,
                            data
                        ).then(response => {
                            setAuthError('')
                            setSignUpContainer(false)
                            Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
                            Cookies.set('photoURL', "No Image");
                            // sessionStorage.setItem('udtl', )
                            window.location.reload()
                            // Navigate('/Review_Booking', { state: TripData })
                        })
                    })
            } catch (error) {
                console.log(error.message);
                switch (error.message) {
                    case 'Firebase: Error (auth/missing-password).':
                        setAuthError('Password cannot be empty')
                        break;
                    case 'Firebase: Error (auth/invalid-email).':
                        setAuthError('Invalid Email')
                        break;
                    case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
                        setAuthError('Password should be at least 6 characters.')
                        break;
                    case 'Firebase: Error (auth/email-already-in-use).':
                        setAuthError('Email already in use.')
                        break;
                    default:
                        break;
                }
                setSignUpLoading(false)
            }
        }
    }

    const signUpForm = () => {
        if (signUpContainer === false) {
            return <>
                <Form.Control
                    placeholder='Email'
                    onChange={(e) => setAuthEmail(e.target.value)} />
                <Form.Control
                    placeholder='Password'
                    type='password'
                    onChange={(e) => setAuthPassword(e.target.value)} />
                <Button
                    variant='contained'
                    style={{ background: 'black' }}
                    onClick={() => loginAction()}>Login</Button>
                <div className='text-end text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Reset Password') + setSignUpContainer('restPassword')}> Forgot password? </div>
                <div className='d-flex'>
                    < div > Don't have an account? Click &nbsp;</div>
                    < div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Sign Up') + setSignUpContainer(true)}> Here</div >
                    <div> &nbsp; to signup</div>
                </div >
            </>
        } else if (signUpContainer === 'restPassword') {
            return <>
                <TextField
                    error={restEmailError}
                    helperText={restEmailError}
                    size='small'
                    label='Reset Password'
                    onChange={(e) => setRestEmailError('') + setResetPasswordEmail(e.target.value)} />
                {passwordResetSuccessmessage ? (
                    <Alert>Password reset mail send to your email</Alert>
                ) : ''}
                <LoadingButton loading={restPasswordLoading} variant='contained' onClick={() => restPasswordAction()}>Reset Password</LoadingButton>
                <div className='d-flex'>
                    < div > Already have an account? Click &nbsp;</div>
                    < div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Sign In') + setSignUpContainer(false)}> Here</div >
                    <div>&nbsp; to Login</div>
                </div >
            </>
        } else {
            return <>
                <Form.Control
                    placeholder='Full Name'
                    onChange={(e) => setFullName(e.target.value)} />
                <Form.Control
                    placeholder='Contact Number'
                    onChange={(e) => setContactNumber(e.target.value)} />
                <Form.Control
                    placeholder='Email'
                    onChange={(e) => setAuthEmail(e.target.value)} />
                <Form.Control
                    placeholder='Password'
                    type='password'
                    onChange={(e) => setAuthPassword(e.target.value)} />
                <LoadingButton
                    loading={signUpLoading}
                    variant="outlined"
                    onClick={() => signupAction()}
                >
                    Sign Up
                </LoadingButton>
                <div className='d-flex'>
                    < div > Already have an account? Click &nbsp;</div>
                    < div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setAuthFormTitle('Sign In') + setSignUpContainer(false)}> Here</div >
                    <div>&nbsp; to Login</div>
                </div >
            </>

        }

    }

    const restPasswordAction = async () => {
        setRestPasswordLoading(true)
        if (resetPasswordEmail === '') {
            setRestEmailError('Please enter email')
        } else {

            try {
                await sendPasswordResetEmail(auth, resetPasswordEmail)
                    .then(res => {
                        console.log(res);
                        setPasswordResetSuccessmessage(true)
                        setRestPasswordLoading(false)
                    })
                    .catch(err => {
                        console.log(err);
                    })
            } catch (error) {
                console.log(error);
            }
        }
    }


    const loginAction = async () => {
        try {
            await signInWithEmailAndPassword(auth, authEmail, authPassword)
                .then(response => {
                    sessionStorage.setItem('photoURL', "No Image")
                    sessionStorage.setItem('udtl', JSON.stringify(response))
                    const data = {
                        uid: response.user.uid,
                        fullName: response.user.displayName,
                        email: response.user.email,
                        phoneNumber: response.user.phoneNumber,
                        userImage: response.user.photoURL
                    }

                    Cookies.set('photoURL', "No Image", { expires: 7 });
                    Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
                    window.location.reload()
                }).catch(err => {
                    if (err.message === "Firebase: Error (auth/user-not-found)") {
                        setAuthError("User Not found")
                    } else {
                        setAuthError("Username or password are not matching")
                    }
                })
        } catch (error) {
            console.log(error.error);
        }
    }


    const signInGoogle = () => {
        setGoogleSigninButtonLoading(true)
        // signInWithGoogle 
        signInWithPopup(auth, provider).then((result) => {
            sessionStorage.setItem('photoURL', result.user.photoURL)
            const data = {
                uid: result.user.uid,
                fullName: result.user.displayName,
                email: result.user.email,
                phoneNumber: result.user.phoneNumber,
                userImage: result.user.photoURL
            }

            instance.post(
                SIGN_IN_WITH_GOOGLE,
                data
            ).then(result => {
                // console.log(result);
                setGoogleSigninButtonLoading(true)

            })


            console.log(data);
            // sessionStorage.setItem('udtl', JSON.stringify(data)) 
            Cookies.set('photoURL', result.user.photoURL, { expires: 7 });
            Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
            // window.location.reload()
            // Navigate('/Review_Booking', { state: TripData })
        }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <div>
            <h3 className='text-center mb-4'><strong>{authFormTitle}</strong></h3>
            <div className='text-center d-flex flex-column gap-3'>
                {authError ? (
                    <Alert severity="error" >{authError}</Alert>
                ) : ""}
                {signUpForm()}
                <div className='d-flex'>
                    <hr className='w-100' />
                    <div>Or</div>
                    <hr className='w-100' />
                </div>
                <strong>Signin with</strong>
                <div>
                    <LoadingButton
                        loading={googleSigninButtonLoading}
                        variant="outlined"
                        startIcon={<FcGoogle style={{ background: '#fff' }} />}
                        onClick={() => signInGoogle()}>
                        Google
                    </LoadingButton>

                </div>
            </div >
        </div >
    )
}

export default Login