import './App.css';
import { Routes, Route } from 'react-router-dom'
import Home from './Pages/Home';
import SelectVehicle from './Pages/SelectVehicle'
import ReviewBookings from './Pages/ReviewBookings';
import PaymentPage from './Pages/PaymentPage';
import OrderConfirmationPage from './Pages/OrderConfirmationPage';
import MyRides from './Pages/MyRides';
import Test from './Pages/Test';


function App() {


  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Select_Vehicle' element={<SelectVehicle />} />
        <Route path='/Review_Booking' element={<ReviewBookings />} />
        <Route path='/Payment' element={<PaymentPage />} />
        <Route path='/Order_Confirm' element={<OrderConfirmationPage />} />
        <Route path='/Profile' element={<MyRides />} />
        <Route path='/Test' element={<Test />} />
      </Routes>
    </>
  );
}

export default App;
