import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Topbar from '../UI/Topbar'
import { Button, Skeleton } from '@mui/material'
import ReceiptIcon from '@mui/icons-material/Receipt';
import { instance } from '../Const/ApiHeader';
import { CANCEL_TRIP_DATA, GET_TRIP_DATA, IMAGE_BASE_URL } from '../Const/ApiConst';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';

function MyRides() {

    const [tripData, setTripData] = useState([])
    const [loadingTripSkeleton, setLoadingTripSkeleton] = useState(true)

    const loadTripData = async () => {
        const data = {
            _id: JSON.parse(Cookies.get('udtl')).uid
        }
        try {
            await instance.post(
                GET_TRIP_DATA,
                data
            ).then((response) => {
                setTripData(response.data.data)
                setLoadingTripSkeleton(false)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadTripData()
    }, [])

    const [cancelLoading, setCancelLoading] = useState(false)

    const cancelTripAction = async (e) => {

        setCancelLoading(true)
        const data = {
            tripId: e
        }
        try {
            await instance.post(
                CANCEL_TRIP_DATA,
                data
            ).then(result => {
                console.log(result);
                const data = {
                    _id: JSON.parse(Cookies.get('udtl')).uid
                }

                instance.post(
                    GET_TRIP_DATA,
                    data
                ).then((response) => {
                    setTripData(response.data.data)
                    setLoadingTripSkeleton(false)
                    setCancelLoading(false)
                }).catch(err => {
                    console.log(err);
                })

            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }


    const tripComponentLive = () => {
        return tripData.map((res, i) => {
            return <div key={i} className='trip-details-container'>
                <div className='d-flex flex-column p-2' style={{ width: '80%' }}>
                    <strong className='text-secondary'>#{res.tripNo}</strong>
                    <h5><strong>
                        {res.source}
                        <TrendingFlatIcon />
                        {res.destination}
                    </strong>
                    </h5>
                    <div className='d-flex gap-2'>
                        {res.vehicle.map((rex, x) => (
                            <div key={x} className='d-flex gap-2'>
                                <div>
                                    <img src={IMAGE_BASE_URL + rex.images[0]} style={{ width: '150px', height: '100px', objectFit: 'cover' }} alt='' />
                                </div>
                                <div>
                                    <div key={x}>
                                        <div>{rex.vehicleName}</div>
                                        <div>{rex.vehicleNo}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='d-flex gap-2'>
                            Driver Not Assigned
                        </div>
                    </div>
                    <hr />
                    <div>Pickup Time : {moment(res.scheduledDate).format("dddd, DD - MMM, YYYY")} - {res.scheduledTime}</div>
                </div>
                <div className='d-flex flex-column justify-content-center gap-4 align-items-center' style={{ width: '20%' }}>
                    {res.tripStatus === 'Booked' ? (
                        <div className='d-flex flex-column gap-4 w-100'>
                            <Button variant='contained' fullWidth color='warning' >{res.tripStatus}</Button>
                            <LoadingButton loading={cancelLoading} variant='outlined' fullWidth onClick={() => cancelTripAction(res._id)} >Cancel</LoadingButton>
                        </div>
                    ) : res.tripStatus === 'Trip Confirmed' ? (
                        <Button variant='contained' fullWidth color='warning' >{res.tripStatus}</Button>
                    ) : res.tripStatus === 'Cancelled' ? (
                        <Button variant='contained' fullWidth color='error' >{res.tripStatus}</Button>
                    ) : (
                        <Button variant='contained' fullWidth color='success' >{res.tripStatus}</Button>
                    )}
                    <div> <ReceiptIcon /> Get Invoice</div>
                </div>
            </div >
        })
    }

    return (
        <div>
            <Topbar />
            <div className='container current-trip'>
                <h4 className='mt-4'>Trip Details</h4>
                {
                    loadingTripSkeleton ? (
                        <>
                            <div style={{
                                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                padding: '15px',
                                margin: '20px 0',
                                borderRadius: '10px'
                            }}>
                                <div className='row'>
                                    <div className='col-2'>
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <Skeleton variant="rectangular" height={115} />
                                    </div>
                                    <div className='col-7'>
                                        <div className='row'>
                                            <div className='col'>
                                                <Skeleton />

                                            </div>
                                            <div className='col'>
                                                <Skeleton />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <Skeleton />

                                            </div>
                                            <div className='col'>
                                                <Skeleton />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Skeleton height={40} />
                                        <Skeleton height={40} />
                                        <Skeleton height={40} />
                                    </div>
                                </div>
                                <hr className='m-0' />
                                <Skeleton className='mt-1' />
                            </div>
                            <div style={{
                                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                padding: '15px',
                                margin: '20px 0',
                                borderRadius: '10px'
                            }}>
                                <div className='row'>
                                    <div className='col-2'>
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <Skeleton variant="rectangular" height={115} />
                                    </div>
                                    <div className='col-7'>
                                        <div className='row'>
                                            <div className='col'>
                                                <Skeleton />

                                            </div>
                                            <div className='col'>
                                                <Skeleton />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <Skeleton />

                                            </div>
                                            <div className='col'>
                                                <Skeleton />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Skeleton height={40} />
                                        <Skeleton height={40} />
                                        <Skeleton height={40} />
                                    </div>
                                </div>
                                <hr className='m-0' />
                                <Skeleton className='mt-1' />
                            </div>
                        </>
                    ) : (tripComponentLive())
                }

            </div>
            <Footer />
        </div>
    )
}

export default MyRides