import React from 'react'

function Services() {
    return (
        <div>
            <h3 className='text-center'>
                <strong>Our Services</strong>
            </h3>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto'
            }}>
                <div className='text-center p-4'>Service One</div>
                <div className='text-center p-4'>Service Two</div>
                <div className='text-center p-4'>Service Three</div>
                <div className='text-center p-4'>Service Four</div>
            </div>
        </div>
    )
}

export default Services